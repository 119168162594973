/* chq-atc -> atc -> add to calendar */

.chq-atc button.chq-atc--button {
  padding: 0;
}

.chq-atc button.chq-atc--button:focus,
.chq-atc button.chq-atc--button:hover {
  background-color: transparent;
}

.chq-atc--button > svg {
  display: none;
}

.chq-atc div.chq-atc--dropdown {
  background-color: black;
  border-radius: 0;
  border: 1px solid #2a2a2a;
  margin-top: 12px;
}

.chq-atc div.chq-atc--dropdown a {
  background-color: black;
  color: white;
  padding: 12px 16px;
  width: 100%;
  outline: none;
}

.chq-atc div.chq-atc--dropdown a:hover {
  background-color: #2a2a2a;
  color: #e0e0e0;
}

.chq-atc div.chq-atc--dropdown a:active {
  background-color: #2a2a2a;
  color: #c0c0c0;
}
