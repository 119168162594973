/*
 WARNING: This is an override style file from 'react-calendar' package ('react-calendar/dist/Calendar.css')
 Proceed with caution.
*/

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: #2a2a2a; /* theme.colors['gray-800'] */
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  padding: 16px;
  /*height: 44px;*/
  /*margin-bottom: 1em;*/
}

.react-calendar__navigation button {
  /*min-width: 44px;*/
  background: none;
  padding: 0;
}

.react-calendar__navigation__label {
  color: white; /* theme.colors['white'] */
  background-color: transparent;
  text-transform: uppercase;
  padding: 0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  /*background-color: #e6e6e6;*/
}

.react-calendar__navigation button[disabled] {
  opacity: 0.3;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  padding-bottom: 8px;
  display: grid !important;
  grid-template-columns: repeat(7, 1fr);
}

.react-calendar__month-view__weekdays__weekday {
  /*padding: 0.5em;*/
  flex-basis: initial !important;
  max-width: initial !important;
}

.react-calendar__month-view__weekdays__weekday > abbr {
  text-decoration: none;
}

.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__month-view__days {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
}

.react-calendar__tile {
  /*max-width: 100%;*/
  /*flex-basis: initial !important;*/
  flex-basis: initial !important;
  max-width: initial !important;
  text-align: center;
  padding: 0;
  background: none;
  color: white; /* theme.colors['white'] */
  width: 40px;
  height: 40px;
}

.react-calendar__tile:disabled {
  color: rgba(255, 255, 255, 0.25); /* theme.colors['white-alpha-5'] */
}

.react-calendar__tile--hover {
  background-color: #e6e6e6;
  color: #484848 !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: black; /* theme.colors['black'] */
  color: white !important;
}

.react-calendar__tile:enabled:hover.react-calendar__tile--hoverEnd {
  color: white; /* theme.colors['black'] */
}

.react-calendar__tile--now {
  border: 1px solid rgba(255, 255, 255, 0.25) !important; /* theme.colors['white-alpha-25'] */
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  border: 1px solid rgba(255, 255, 255, 0.5); /* theme.colors['white-alpha-5'] */
  background: none;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #ca3a95; /* theme.colors['pink-500'] */
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #ca3a95; /* theme.colors['pink-500'] */
}

.react-calendar__tile--active.react-calendar__tile--range:hover {
  background-color: black;
}

.react-calendar__month-view__days__day--neighboringMonth {
  /*color: #757575;*/
  color: rgba(255, 255, 255, 0.5); /* theme.colors['white-alpha-5'] */
}

/* Hide `Calendar`'s default value in favor of `tileContent` prop */
.react-calendar__tile.react-calendar__month-view__days__day abbr {
  display: none;
}
