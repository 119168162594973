html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Neusa Next Pro Compact', sans-serif;
  text-transform: uppercase;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #484848 #2a2a2a;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: #2a2a2a;
}

*::-webkit-scrollbar-thumb {
  background-color: #484848;
  border-radius: 4px;
  border: 4px solid #484848;
}

/* Carousel start */
.splide__pagination {
  padding-top: 24px;
}

.splide__pagination__page {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: none;
  background: #484848; /* gray 700*/
  margin-right: 8px;
}

.splide__pagination__page.is-active {
  background: #fff;
  transform: none;
}
/* Carousel end */
