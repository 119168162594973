article {
  font-family: Neusa Next Pro;
  font-style: normal;
  font-weight: normal;
}

article a {
  color: #ca3a95;
}

article a:hover {
  color: #bd2c82;
}

article a:active {
  color: #ab1866;
}

article blockquote {
  background: #2a2a2a;
  border-radius: 2px;
  font-style: italic;
  padding: 16px;
}

article h1,
article h2,
article h3,
article h4,
article h5,
article h6 {
  font-family: 'Neusa Next Pro Compact';
  text-transform: uppercase;
}

article h1 {
  font-size: 96px;
  line-height: 104px;
}

article h2 {
  font-size: 56px;
  line-height: 64px;
}

article h3 {
  font-size: 36px;
  line-height: 48px;
}

article h4 {
  font-size: 24px;
  line-height: 32px;
}

article h5 {
  font-size: 21px;
  line-height: 24px;
}

article h6 {
  font-size: 16px;
  line-height: 24px;
}

article iframe,
article img {
  max-width: 100% !important;
  object-fit: contain;
}

article li:not(:last-child) {
  margin-bottom: 8px;
}

article p {
  font-size: 21px;
  line-height: 32px;
}

@media (max-width: 768px) {
  article p {
    font-size: 18px;
  }
}

article pre {
  background: #2a2a2a;
  border-radius: 2px;
  font-family: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace;
  padding: 16px;
}

article table {
  border-collapse: collapse;
  max-width: 100%;
}

article td {
  border: 1px solid #2a2a2a;
  padding: 8px 16px;
}

article th {
  border: 1px solid #2a2a2a;
  padding: 8px 16px;
}
